<!-- 设备展示 -->
<template>
  <div class="container">
    <el-card>
      <div class="facility">
        <div class="tile_box">
          <div class="title">设备展示</div>
        </div>
        <div v-if="cereOrderDetails.length > 0">
          <div class="list">
            <div
              class="list-item"
              v-for="(item, index) in jsonData.cereOrderDetails"
              :key="index"
            >
              <el-descriptions size="small" border>
                <el-descriptions-item label="设备名称">{{
                  item.vehicleName
                }}</el-descriptions-item>
                <el-descriptions-item label="设备类型"
                  >{{ item.firstLevelType }}/{{
                    item.secondLevelType
                  }}</el-descriptions-item
                >
                <el-descriptions-item label="设备规格">{{
                  item.vehicleModel
                }}</el-descriptions-item>
              </el-descriptions>
              <el-descriptions size="small" border>
                <el-descriptions-item label="进场日期">{{
                  item.slottingTime
                }}</el-descriptions-item>
                <el-descriptions-item label="数量"
                  >{{ item.count }}台</el-descriptions-item
                >
                <el-descriptions-item label="工作量"
                  >{{ item.leaseWork
                  }}{{ item.leaseUnit }}</el-descriptions-item
                >
              </el-descriptions>
              <el-descriptions size="small" border>
                <el-descriptions-item label="单价"
                  ><strong style="color: red; font-size: 14px">{{
                    item.totalPrice
                  }}</strong
                  >/{{ item.leaseUnit }}</el-descriptions-item
                >
              </el-descriptions>
              <div class="sendcar">
                <el-button
                  type="primary"
                  size="small"
                  @click="openSendCar(item, index)"
                  >派车 ({{
                    submitData.cereOrderDetails[index].vehicleIds.length
                      ? submitData.cereOrderDetails[index].vehicleIds.length
                      : item.vehicleIds.length
                  }})</el-button
                >
              </div>
            </div>
          </div>
          <div class="total_remark">
            <div>订单合计：</div>
            <strong style="color: red; font-size: 18px">
              ￥{{ jsonData.amountTotal }}
            </strong>
          </div>
          <div class="total_remark">
            <div>改价备注：</div>
            <el-input type="textarea" v-model="modifyParam.remark"></el-input>
          </div>
          <div class="total_price">
            <div>实际金额：</div>
            <el-input
              size="small"
              type="number"
              v-model="modifyParam.afterModifyPrice"
            ></el-input
            >&nbsp; 元
          </div>
        </div>
        <el-empty :image-size="200" v-else></el-empty>
        <div class="btn">
          <el-button size="small" @click="$router.back(-1)">返回</el-button>
          <el-button size="small" @click="submit">提交</el-button>
        </div>
      </div>
    </el-card>
    <!-- 车辆信息 -->
    <el-dialog
      title="车辆信息"
      :visible.sync="dialogVisible"
      width="50%"
      :close-on-click-modal="false"
    >
      <el-scrollbar class="carlist">
        <div class="carlist_item" v-for="(item, index) in carList" :key="index">
          <el-checkbox-group v-model="item.isChecked">
            <el-checkbox size="medium"></el-checkbox>
          </el-checkbox-group>
          <div>
            <el-image :src="item.vehicleImg"></el-image>
          </div>
          <div class="car_content">
            <el-descriptions size="small">
              <el-descriptions-item label="车辆名称">{{
                item.vehicleName
              }}</el-descriptions-item>
            </el-descriptions>
            <el-descriptions size="small">
              <el-descriptions-item label="类型"
                >{{ item.firstLevelType }}/{{
                  item.secondLevelType
                }}</el-descriptions-item
              >
              <el-descriptions-item label="型号">{{
                item.vehicleModel
              }}</el-descriptions-item>
            </el-descriptions>
            <el-descriptions size="small">
              <el-descriptions-item label="地址"
                >{{ item.vehicleProvince }}{{ item.vehicleCity
                }}{{ item.vehicleCounty }}</el-descriptions-item
              >
            </el-descriptions>
          </div>
        </div>
      </el-scrollbar>
      <div class="btn">
        <el-button size="small" @click="dialogVisible = false">取消</el-button>
        <el-button size="small" @click="addCar">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getVehicleInfoByOrder } from "@/api/car";
import { getOrderById, modifyPrice } from "@/api/order";

export default {
  data() {
    return {
      jsonData: {},
      dialogVisible: false,
      carList: [],
      maxNum: 0,
      submitData: {
        cereOrderDetails: [
          {
            count: 0,
            requireDetailId: 0,
            vehicleIds: [],
          },
        ],
        requiredId: null,
      },
      carIndex: null,
      newCarList: [],
      price: "",
      modifyParam: {
        afterModifyPrice: 0,
        remark: "",
        orderId: 0,
        vehicleIds: [],
      },
      cereOrderDetails: [],
    };
  },
  created() {
    getOrderById(this.$route.query.id).then((res) => {
      this.jsonData = res.data;
      this.cereOrderDetails = res.data.cereOrderDetails;
      this.submitData.cereOrderDetails = res.data.cereOrderDetails.map(
        (item) => {
          return {
            count: item.count,
            requireDetailId: item.requireDetailId,
            vehicleIds: item.vehicleIds,
          };
        }
      );
    });
  },
  methods: {
    openSendCar(item, index) {
      if (
        this.submitData.cereOrderDetails[
          this.submitData.cereOrderDetails.length - 1
        ].vehicleIds.length > 0
      ) {
        this.submitData.cereOrderDetails.push({
          count: 0,
          requireDetailId: 0,
          vehicleIds: [],
        });
      }
      this.carIndex = index;
      this.maxNum = item.vechileNumber;
      this.requireDetailId = item.id;
      let data = {
        firstLevelType: item.firstLevelTypeId,
        secondLevelType: item.secondLevelTypeId,
        checkStatus: this.$route.query.orderStatus,
        vehicleIds: item.realVehicleIds, // 根据小程序修改传值
      };
      getVehicleInfoByOrder(data).then((res) => {
        if (res.data.length == 0) {
          this.$message.warning("暂无相关车辆信息");
          return;
        }
        this.dialogVisible = true;
        if (this.submitData.cereOrderDetails[this.carIndex] == undefined) {
          this.submitData.cereOrderDetails.push({
            count: 0,
            requireDetailId: 0,
            vehicleIds: [],
          });
        }
        res.data.forEach((item) => {
          item.isChecked = false;
          this.submitData.cereOrderDetails[this.carIndex].vehicleIds.forEach(
            (v) => {
              if (item.id == v) {
                item.isChecked = true;
              }
            }
          );
          if (
            this.jsonData.cereOrderDetails[this.carIndex].vehicleIds != null
          ) {
            this.jsonData.cereOrderDetails.forEach((j) => {
              j.vehicleIds.forEach((k) => {
                if (item.id == k) {
                  item.isChecked = true;
                  this.submitData.cereOrderDetails[this.carIndex].count =
                    j.count;
                  this.submitData.cereOrderDetails[
                    this.carIndex
                  ].requireDetailId = j.orderId;
                  this.submitData.cereOrderDetails[this.carIndex].vehicleIds =
                    j.vehicleIds;
                }
              });
            });
          }
        });
        this.carList = res.data;
      });
    },
    addCar() {
      this.submitData.cereOrderDetails[this.carIndex].vehicleIds = [];
      this.carList.forEach((item) => {
        if (item.isChecked == true) {
          this.submitData.cereOrderDetails[this.carIndex].vehicleIds.push(
            item.id
          );
        }
      });
      this.submitData.cereOrderDetails[this.carIndex].requireDetailId =
        this.requireDetailId;
      this.submitData.cereOrderDetails[this.carIndex].count =
        this.submitData.cereOrderDetails[this.carIndex].vehicleIds.length;
      if (
        this.submitData.cereOrderDetails[this.carIndex].vehicleIds.length >
        this.maxNum
      ) {
        this.$message.warning("不能超过设备数量最大值");
        return;
      }
      this.submitData.requiredId = this.$route.query.id;
      this.dialogVisible = false;
    },
    submit() {
      var isSubmit = true;
      this.submitData.cereOrderDetails.forEach((item) => {
        if (item.vehicleIds.length <= 0 && isSubmit) {
          isSubmit = true;
        } else {
          isSubmit = false;
        }
      });
      if (isSubmit === true) {
        this.$message.warning("最少要有一辆车");
        return;
      }
      if (this.modifyParam.afterModifyPrice == 0) {
        this.$message.warning("请填写改价金额");
        return;
      }
      this.submitData.cereOrderDetails.forEach((item) => {
        item.vehicleIds.forEach((v) => {
          this.modifyParam.vehicleIds.push(v);
        });
      });
      this.modifyParam.cereOrderDetails = this.submitData.cereOrderDetails;
      this.modifyParam.orderId = this.$route.query.id;
      let modifyParam = this.modifyParam;
      modifyParam.cereOrderDetails = modifyParam.cereOrderDetails.filter(
        (e) => e.vehicleIds && e.vehicleIds.length
      ); // 去空过滤
      delete modifyParam.vehicleIds; // 根据小程序传值无改字段
      modifyPrice(modifyParam).then((res) => {
        this.$message.success("提交成功");
        this.$router.go(-1);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.total_price {
  display: flex;
  align-items: center;
  .el-input {
    width: 200px;
  }
}
.total_remark {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .el-textarea {
    width: 200px;
  }
}
::v-deep .el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
  width: 110px;
}
.btn {
  margin-top: 40px;
  text-align: center;
  .el-button {
    width: 150px;
  }
  .el-button:nth-child(1) {
    background-color: #ffffff;
    color: #fcb110;
    border: 1px solid #fcb110;
  }
  .el-button:nth-child(2) {
    background-color: #fcb110;
    color: #fff;
    border: 1px solid #fcb110;
  }
}
.facility {
  text-align: left;
  margin-top: 30px;
  .tile_box {
    border-bottom: 2px solid #dddddd;
    position: relative;
    .title {
      position: absolute;
      top: -30px;
      font-size: 18px;
      font-weight: bold;
      border-bottom: 2px solid #fcb110;
      width: 72px;
      line-height: 30px;
    }
  }
  .list {
    .list-item {
      margin-top: 20px;
      .sendcar {
        margin-top: 10px;
        text-align: right;
      }
    }
  }
}
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}
.carlist {
  height: 350px;
  .carlist_item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    padding: 10px;
    .el-image {
      margin: 0 20px;
      width: 100px;
      height: 100px;
    }
    .car_content {
    }
  }
}
</style>
